import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';

const Projects = () => {
  const { projects } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Projects" />
          {projects.map((project) => {
            const { title, info, info2, url, repo, img, id, demo } = project;

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={500}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
                      <div>
                        <p>
                          {info}
                        </p>
                        <p className="mb-4">{info2 || ''}</p>
                      </div>
                      {url && <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={url}
                      >
                        See Live
                      </a>}
                      {demo && <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={demo}
                      >
                        Demo video
                      </a>}

                      {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={repo}
                        >
                          Source Code
                        </a>
                      )}
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1000}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      <a
                        href={url || '#!'}
                        target="_blank"
                        aria-label="Project Link"
                        rel="noopener noreferrer"
                      >
                        <Tilt
                          options={{
                            reverse: false,
                            max: 8,
                            perspective: 1000,
                            scale: 1,
                            speed: 300,
                            transition: true,
                            axis: null,
                            reset: true,
                            easing: 'cubic-bezier(.03,.98,.52,.99)',
                          }}
                        >
                          <div data-tilt className="thumbnail rounded">
                            <ProjectImg alt={title} filename={img} />
                          </div>
                        </Tilt>
                      </a>
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
          <div className='mini-projects'>
            <Title title="Minor projects"></Title>
            <h4 className='mini-projects-subtitle'>Small stuff I did but I'm still proud of!</h4>
            <Row>
              <Col lg={12} sm={12}>
                <div className="mini-projects-list">
                  <ul>
                    <li><p><a href="https://chrome.google.com/webstore/detail/gmailfindbycontact/hjbbigoinigneipigfbaelaonnokbdif">gmail find by contact</a> - a chrome extension to save you some time in gmail</p></li>
                    <li><p><a href="https://chrome.google.com/webstore/detail/youtubescraper/bnhpbgdbhjobeekhbpicbfddmlkfkmih">Youtube scraper</a> - chrome extensions to scrape Youtube links from web pages. Meant mostly for use in various facebook music groups. <a href="https://github.com/sthuck/youtube-extension">(Source)</a></p></li>
                    <li><p><a href="https://github.com/sthuck/SocketsProject">File transfer</a> - practicing async io and socket programming with C</p></li>
                    <li>
                      <p>
                        <a href="https://github.com/wix-incubator/github-monorepo-search">Github monorepo search</a> - chrome extension that allows to search only inside current folder, useful for mono-repos.
                      </p>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Projects;
